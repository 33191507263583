import React, { useState, useEffect, useRef, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from "react-router-dom";
import { CircularProgress, TextField, Typography, Button, Container, Box } from '@mui/material';
import Page from "../components/Page";
import { styled } from "@mui/material/styles";

const styles = makeStyles((theme) => ({
  gridContainer: {
    margin: '0.5rem 0',
    width: '100%',
  },
  h2: {
    marginLeft: '0 !imporant',
  },
  textfield: {
    width: '100%',
    marginBottom: 4,

    '& div': {
      maxWidth: '100%',
    },
  },
  root: {
    margin: 4,
    '&$error': {
      color: 'rgba(180, 60, 0, 0.87)',
    },
  },
  error: {
    '&.MuiFormHelperText-root.Mui-error': {
      color: 'rgba(180, 60, 0, 0.87)',
    },
  },
  button: {
    textTransform: 'none',
    // fontFamily: font.heading,
    margin: theme.spacing(1.5),
  },
  initialHelperText: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: 0,
    fontSize: '0.75rem',
    marginTop: 3,
    textAlign: 'left',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
  },
  numberInput: {
    width: '42px',
  }
}));

const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
  }));

export default function ComingSoonComponent() {
  const [value, setValue] = React.useState('Controlled');

  return (
    <Page title="404 Page Not Found">
      <Container>
        <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
          <Typography variant="h3" paragraph>
            Coming Soon
          </Typography>

          <Typography sx={{ color: "text.secondary" }}>
            Sorry, we are currently working on this feature
          </Typography>

          <Box
            component="img"
            src="/static/illustrations/illustration_register.png"
            sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
          />

          <Button
            to="/"
            size="large"
            variant="contained"
            component={RouterLink}
          >
            Go to Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
 